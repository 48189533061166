import axios from "axios";
import QS from 'qs';

// export const baseURL = " http://b.ziranyukj.com:8080";


export const baseURL = "http://b.ziranyukj.com:8080";

const instance = axios.create({
  baseURL: baseURL, // 设置API的基础路径
  // timeout: 10000, // 设置请求超时时间
  withCredentials:true,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Headers': 'X-Requested-With,Content-Type',
    // 'Access-Control-Allow-Methods': 'PUT,POST,GET,DELETE,OPTIONS',
    'Content-Type': 'application/json; charset=utf-8',
  }
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => error
);

instance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    if(response.status === 200){
        return response.data
    }
    return response;
  },
  (error) => {
    // console.log('rererer',error)
    // 对响应错误做点什么
    // return Promise.reject(error);
    return error
  }
);
export default instance
