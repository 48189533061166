import RouterComponent from "../routes/index";

import { shopMenu, brandMenu } from "../common/menu";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SwapOutlined,
  UserSwitchOutlined,
  UserOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAtomValue, useAtom, useSetAtom } from "jotai";
import {
  loginBasicInfoAtom,
  brandOrShopMsgAtom,
  brandAuthAtom,
  noticeAtom,
  activeMenuAtom,
  subMenuShowAtom,
  menuCollapsedAtom
} from "../store/index.js";
import Styles from "./index.module.scss";
import { LOGIN_TYPE } from "../common/constants.js";
import {
  getShopListRequest,
  brandChangeShop,
  shopChangeTopShop,
  shopChangeTopBrand,
  getBrandAndShopMsg,
  getAuthPermission,
} from "../api/index.js";
import {
  Layout,
  Avatar,
  Button,
  Menu,
  Space,
  Drawer,
  Flex,
  Image,
  List,
  Skeleton,
  Divider,
} from "antd";
const { Header, Content, Sider } = Layout;
const PageHeader = function (props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [basicInfo, setBasicInfo] = useAtom(loginBasicInfoAtom);
  const navigate = useNavigate();
  const [brandList, setBrandAndShopMsg] = useState([]);
  const [brandOrShopMsg, setBrandOrShopMsg] = useAtom(brandOrShopMsgAtom);

  const isBrand =LOGIN_TYPE[basicInfo.loginType] === "品牌";
  const setNotice = useSetAtom(noticeAtom);
  const brandAuth = useAtomValue(brandAuthAtom);

  useEffect(() => {
    if(openDrawer){
      getShopListRequest({ authUserId: brandAuth.brandId }).then((res) => {
        if (res.state === "1000" && res.data) {
          setBrandAndShopMsg(res.data);
        }
      });
    }
    
  }, [openDrawer]);
  useEffect(() => {
    let params = isBrand
      ? { brandIdenty: basicInfo.brandIdenty }
      : { shopIdenty: basicInfo.shopIdenty };
    getBrandAndShopMsg(params).then((res) => {
      if (res.state === "1000" && res.data) {
        setBrandOrShopMsg(res.data);
      }
    });
  }, [isBrand]);

  const drawerStyles = {
    content: {},
    header: {
      display: "none",
    },
    body: {},
    footer: {},
    mask: {
      display: "none",
    },
  };
  const showDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const loginOut = () => {
    navigate("/login");
  };
  const reloadAfterToggle = function(isBrand){
    if (isBrand) {
          const  { iframeurl } = brandMenu[0]
          navigate('/main',{ state: { iframeurl }})
        } else {
          navigate('/home')
        }
  }
  const switchToShop = async (item) => {
    const params = {
      shopIdenty: item.commercialId,
    };
    let res;
    if (isBrand) {
      // 品牌切换门店
      res = await brandChangeShop(params);
    } else {
      res = await shopChangeTopShop(params);
    }
    if (res.state === "1000") {
      const { brandIdenty, creatorId, creatorName, shopIdenty,roleId } = res.data;

      setBasicInfo({
        ...basicInfo,
        brandIdenty,
        creatorId,
        creatorName,
        shopIdenty,
        loginType: 1, //1:门店
        roleId
      });
      // navigate(0);
      reloadAfterToggle(false)
    } else {
      setNotice({ show: true, desc: { title: "切换失败", desc: res.msg } });
    }
  };
  const intoBrand = async function () {
    const params = {
      brandUserId: brandAuth.brandId,
    };
    const res = await shopChangeTopBrand(params);
    if (res.state === "1000") {
      const { brandIdenty, creatorId, creatorName, shopIdenty,roleId } = res.data;

      setBasicInfo({
        ...basicInfo,
        brandIdenty,
        creatorId,
        creatorName,
        shopIdenty,
        loginType: 3, //3:品牌
        roleId
      });
      reloadAfterToggle(true)
      // navigate(0);
    }
  };
  return (
    <>
      <div className={Styles.header}>
        <div>
          <img
            className={Styles.logo}
            src={require("../assets/logo_icon.png")}
          ></img>
          <div className={Styles.separator}></div>
          <div className={Styles.name}>
            {brandOrShopMsg.name || brandOrShopMsg.commercialName}
          </div>
        </div>
        <Space size={20}>
          <Space size={5} style={{ cursor: "pointer" }}>
            <UserOutlined style={{ fontSize: "18px" }} />
            {basicInfo.creatorName}
          </Space>

          {brandAuth.isBrand ? (
            <Space
              size="small"
              style={{ cursor: "pointer" }}
              onClick={showDrawer}
            >
              <SwapOutlined />
              {!openDrawer ? "切换" : "关闭"}
            </Space>
          ) : (
            <Flex
              onClick={loginOut}
              gap={5}
              align="center"
              style={{ cursor: "pointer" }}
            >
              <LoginOutlined style={{ color: "red", fontSize: "16px" }} />
              <span>退出登录</span>
            </Flex>
          )}
        </Space>
      </div>

      <Drawer
        footer={
          <Flex justify="center">
            <Button
              onClick={loginOut}
              style={{ borderRadius: "15px", margin: "10px auto" }}
            >
              退出登录
            </Button>
          </Flex>
        }
        className={Styles.drawer}
        styles={drawerStyles}
        onClose={onClose}
        open={openDrawer}
      >
        {!isBrand && (
          <Flex
            style={{ marginBottom: "10px" }}
            justify="center"
            align="center"
            gap={5}
          >
            <Flex
              onClick={intoBrand}
              style={{ display: "inline-flex", cursor: "pointer" }}
              justify="center"
              align="center"
              gap={5}
            >
              <Image
                preview={false}
                width={20}
                src={require("../assets/into_brand_icon.png")}
              ></Image>
              <span>进入品牌管理平台</span>
            </Flex>
          </Flex>
        )}

        <List
          itemLayout="horizontal"
          dataSource={brandList}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Image
                    preview={false}
                    width={35}
                    src={item.commercialLogo || item.doorPhoto}
                  ></Image>
                }
                title={<span>{item.commercialName}</span>}
                description={item.commercialAdress}
                style={{ alignItems: "center" }}
              />
              <SwapOutlined
                onClick={() => switchToShop(item)}
                style={{ fontSize: "16px", cursor: "pointer" }}
              />
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};
const Container = function () {
  const [subMenuList, setSubMenuList] = useState({title:'',list:[]});

  const [collapsed, setCollapsed] = useAtom(menuCollapsedAtom);
  const [subMenuShow, setSubMenuShow] = useAtom(subMenuShowAtom);
  const [activeMenu, setActiveMenu] = useAtom(activeMenuAtom);
  const defaultOpenKeys = activeMenu.subKey ? [activeMenu.subKey[0]] : [];
  const defaultSelectedKeys = activeMenu.subKey || [];
  const [basicInfo, setBasicInfo] = useAtom(loginBasicInfoAtom);
  const isBrand = LOGIN_TYPE[basicInfo.loginType] === "品牌";
  // const [permissionList, setPermissionList] = useState([]);
  const [menu, setMenu] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const state = location.state;

  function formatMenuWithAuth(arr, permissionList) {
    let newArr = [];
    arr.forEach((item) => {
      let auth = permissionList.find((d) => d.code === item.code);

      if (!item.children) {
        if (!item.code || auth) {
          newArr.push(item);
        }
      } else {
        const childrenList = formatMenuWithAuth(item.children, permissionList);
        if (childrenList.length) {
          newArr.push({ ...item, children: childrenList });
        }
      }
    });
    return newArr;
  }
  
  // 根据路由匹配菜单
  useEffect(() => {
    let { iframeurl } = location.state || {};
    function findMenuMatch(items, root = null,keys=[]) {
     
      for (let item of items) {
        if (item.url === pathname) {
          let match = (iframeurl && iframeurl === item.iframeurl) || !item.iframeurl;
          if (match) {
            let selectMenu = []
            if(root){
              root.children.forEach(i=>{
                let obj = {key:i.key,label:i.label,children:i.children,url:i.url,iframeurl:i.iframeurl,iframeurlparams:i.iframeurlparams};
                selectMenu.push(obj)
              })

            }
            setSubMenuList({ title: root ? root.label : '', list: selectMenu });
            setActiveMenu({ rootKey: root ? root.key : item.key, subKey: [...keys,item.key],openKeys:[...keys] });
            
            if(selectMenu.length && !collapsed){
              setSubMenuShow(true)
            }
            return true;
          }
        }
        
        if (item.children && item.children.length) {
          let tmpKeys = root ? [...keys,item.key] : []

          let tempRoot = root ? root : item;
          if (findMenuMatch(item.children, tempRoot , tmpKeys)) {
            return true;
          }
        }
      }
      return false;
    }
    findMenuMatch(menu);
  }, [pathname, state,menu]);
  useEffect(() => {
    // 获取默认菜单；
    const authParams = {
      brandIdenty: basicInfo.brandIdenty,
      shopIdenty: basicInfo.shopIdenty,
      roleId: basicInfo.roleId,
      platform:isBrand ? 3 : 1, // 1:门店 3:品牌 2：门店
    };

    getAuthPermission(authParams).then((res) => {
      let perssion_list = []
      if (res.state === "1000") {
        // setPermissionList(res.data);
        perssion_list = res.data
      }
      if (isBrand) {
        setMenu(formatMenuWithAuth(brandMenu, perssion_list));
      } else {
        setMenu(formatMenuWithAuth(shopMenu, perssion_list));
      }
    });
  }, [isBrand]);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    if (!collapsed) {
      setSubMenuShow(false);
    } else if (activeMenu.subKey.length) {
      setSubMenuShow(true);
    }
  };
  function navigateToUrl({url,iframeurl,iframeurlparams}){
    if (url) {
      if (iframeurl) {
        navigate(url, { state: { iframeurl, iframeurlparams } });
      } else {
        navigate(url);
      }
    } else {
      navigate("/main");
    }
  }
  const findDefaultMenu = function (list) {
    let item = list[0];
      if (item.children && item.children.length) {
        return findDefaultMenu(item.children);
      } else {
        return item;
      }
  };
  const menuChange = function (data, type) {
    let selectMenu = [];
    let title = '';
    menu.forEach(item => {
      if (item.key === data.key && item.children) {
        title = item.label;
        item.children.forEach(i => {
          let obj = { key: i.key, label: i.label, children: i.children, url: i.url, iframeurl: i.iframeurl, iframeurlparams: i.iframeurlparams };
          selectMenu.push(obj);
        });
      }
    });

    if (selectMenu.length) {
      setSubMenuList({ title, list: selectMenu });
      setSubMenuShow(true);
      const defaultMenu = findDefaultMenu(selectMenu);
      navigateToUrl(defaultMenu);
    } else {
      setSubMenuShow(false);
      setSubMenuList({ title: '', list: [] });
      if (type !== "hover") {
        navigateToUrl(data);
      }
    }
  };

  const subMenuChange = function (menu) {
    navigateToUrl(menu.item.props)
  };

  const menuMouseEnter = function (menu) {
    // 折叠状态下 鼠标进入显示子菜单
    // if (collapsed) {
    //   menuChange(menu,'hover');
    // }
  };
  const subMenuMouseLeave = function () {
    if (collapsed) {
      setSubMenuShow(false);
      
    }
  };
  const onOpenChange = function (openKeys) {
    setActiveMenu({ ...activeMenu,openKeys });
  };
  return (
    <Layout>
      <Header>
        <PageHeader></PageHeader>
      </Header>
      <Layout>
        <Sider collapsible={false}>
          <Flex
            align="center"
            vertical
            gap={10}
            className={Styles["menuContiner"]}
          >
            {menu.map((item) => {
              return (
                <Flex
                  onClick={() => {
                    menuChange(item);
                  }}
                  className={[
                    Styles.menuItem,
                    activeMenu.rootKey === item.key ? Styles.active : "",
                  ].join(" ")}
                  vertical
                  gap={5}
                  justify="space-between"
                  align="center"
                  key={item.key}
                >
                  <div
                    className={Styles.icon}
                    onMouseEnter={() => menuMouseEnter(item)}
                   
                  >
                    {activeMenu.rootKey === item.key
                      ? item.activeIcon
                      : item.icon}
                  </div>
                  <div>{item.label}</div>
                </Flex>
              );
            })}
          </Flex>
          <div className={Styles.collapsedBtn} onClick={toggleCollapsed}>
            <div >
            
                <Flex vertical gap={2} align="center">
                 {collapsed ? 
                 <> <MenuUnfoldOutlined /> <span>展开</span></> 
                 : 
                 <> <MenuFoldOutlined /> <span>收起</span></>}
                 
                </Flex>
            </div>
          </div>
        </Sider>
        <Content>
          {(subMenuShow && subMenuList.title && subMenuList.list) ? (
            <Flex vertical >
              <div className={Styles.subMenuTitle}>
                <Divider type="vertical" />
                {subMenuList.title}
              </div>
              <Menu
              className={Styles.subMenu}
                onMouseLeave={subMenuMouseLeave}
                style={{ width: 210, flex: 1 }}
                theme="light"
                mode="inline"
                items={subMenuList.list}
                onSelect={subMenuChange}
                defaultSelectedKeys={defaultSelectedKeys}
                defaultOpenKeys={defaultOpenKeys}
                selectedKeys={activeMenu.subKey}
                openKeys={activeMenu.openKeys}
                onOpenChange={onOpenChange}
              />
            </Flex>
          ): null}
          <div style={{ flex: 1, height: " 100%", overflowY: "auto" }}>
            <RouterComponent />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Container;
