import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.module.scss';
import App from './App';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN'; // 引入中文语言包
import 'dayjs/locale/zh-cn'; // 日期组件的底层依赖 dayjs 的本地化
import  './utils/rem'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
    <Router>
        <App />
    </Router>
    </ConfigProvider>
    
  </React.StrictMode>
);
