import request from './request'
export const loginRequest = params => request.post( '/marketing/internal/login/action',  params) 
export const getShopListRequest = params => request.get( '/marketing/internal/managerShop/changeShopList',  {params}) 
export const brandChangeShop = params => request.post( '/marketing/internal/managerShop/brandChangeShop',  params) 
export const shopChangeTopShop = params => request.post( '/marketing/internal/managerShop/shopChangeToShop',  params) 
export const shopChangeTopBrand = params => request.post( '/marketing/internal/managerShop/shopChangeToBrand',  params) 
export const getBrandAndShopMsg = params => request.get( '/marketing/internal/managerShop/brandOrShopMsg', {params}) 

// 首页销售统计
export const getSaleAmount = params => request.post( '/marketing/internal/mainDashborad/saleAmount',  params) 
// 首页会员资产消耗统计
export const getCustomerProperty = params => request.post( '/marketing/internal/mainDashborad/customerProperty',  params) 
// 首页商品售卖统计
export const getDishShopSaleReport = params => request.post( '/marketing/internal/mainDashborad/dishShopSaleReport',  params) 
export const getStaffSalaryReport = params => request.post( '/marketing/internal/mainDashborad/staffSalaryReport',  params) 

export const getCustomerCount = params => request.post( '/marketing/internal/mainDashborad/customerCount',  params) 
export const getAllDishSaleOrder = params => request.post( '/marketing/internal/mainDashborad/allDishSaleOrder',  params) 

export const getAllDishTypeSaleOrder = params => request.post( '/marketing/internal/mainDashborad/allDishTypeSaleOrder',  params) 

export const getAllStaffSaleOrder = params => request.post( '/marketing/internal/mainDashborad/allStaffSaleOrder',  params) 


export const getAuthPermission = params => request.post( '/marketing/internal/authUserApi/authPermission',  params) 

