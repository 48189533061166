import { notification ,Image} from "antd";
import noticeIcon from "../assets/login_notice_icon.png";
import {useSetAtom } from 'jotai'
import { noticeAtom } from "../store"; 
export default function useNotice(){
    const [api, contextHolder] = notification.useNotification();
    const setNotice = useSetAtom(noticeAtom)
    const openNotification = ({title ,desc}) => {
        api.open({
          message: title || '请求失败',
          description: desc,
          showProgress: true,
          pauseOnHover: true,
          icon: (
            <Image preview={false} height={25} width={25} src={noticeIcon} />
          ),
          duration: 4.5,
          className: "notice-class",
          style: {
           
            backgroundColor: "#a94442",
            borderColor: "#ebccd1",
            fontSize: "12px",
          },
          onClose:()=>{setNotice({show:false,desc:{}})}
        });
      };
      return {
        contextHolder,
        openNotification
      }
}
