import { atom } from "jotai";
// 用于存储到localstorage中
const atomWithLocalStorage = (key, initialValue) => {
  const getInitialValue = () => {

    const item = localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item);
    }
    return initialValue;
  };
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === "function" ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      localStorage.setItem(key, JSON.stringify(nextValue));
    }
  );
  return derivedAtom;
};
const atomWithSessionStorage = (key, initialValue) => {
  const getInitialValue = () => {

    const item = sessionStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item);
    }
    return initialValue;
  };
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === "function" ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      sessionStorage.setItem(key, JSON.stringify(nextValue));
    }
  );
  return derivedAtom;
};
// 登录接口返回信息
const loginBasicInfoAtom = atomWithSessionStorage("loginBasicInfo", {
  brandIdenty: "",
  creatorId: "",
  creatorName: "",
  shopIdenty: "",
});
const brandOrShopMsgAtom = atomWithSessionStorage("brandOrShopMsg", {

});
// 是否通过品牌登录
const brandAuthAtom = atomWithSessionStorage("brandAuth", {isBrand:false,brandId:''}
);
// 激活的菜单信息
const activeMenuAtom = atomWithSessionStorage('activeMenu',{
  rootKey:'1',
  subKey:[]
})

const noticeAtom = atom({
    title:'',
    show:false,
    desc:''
})
// 菜单展开状态
const subMenuShowAtom = atomWithSessionStorage('subMenuShow',false)
const menuCollapsedAtom = atomWithSessionStorage('menuCollapse',false)

export { loginBasicInfoAtom ,noticeAtom ,brandOrShopMsgAtom,brandAuthAtom,activeMenuAtom,subMenuShowAtom,menuCollapsedAtom};
