import './App.module.scss';
import RouterComponent from './routes/index'
import useNotice from './common/notice.js'
import { useNavigate, useLocation } from 'react-router-dom';

import {noticeAtom} from './store/index.js'
import { useEffect } from 'react';
import { useAtomValue } from "jotai";
import Container from '../src/pages/index.jsx'
function App() {
  const {openNotification, contextHolder} = useNotice();
  const {show:noticeShow , desc:noticeDesc} = useAtomValue(noticeAtom);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(()=>{
    if(noticeShow){
      openNotification(noticeDesc)
    }
  },[noticeShow])

  const isLoginPage = location.pathname === "/login"
  return (
    <div className="App">
       {noticeShow && contextHolder}
       {isLoginPage ?  <RouterComponent /> :  <Container></Container>}
    </div>
  );
}

export default App;
