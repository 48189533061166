import  { lazy } from "react";
import {  Route, Routes,Navigate } from 'react-router-dom';
import {  useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAtomValue } from "jotai";
import {loginBasicInfoAtom} from '../store/index'

const Login = lazy(() => import('../pages/login'));  
const Home = lazy(() => import('../pages/home'));  
const Main = lazy(() => import('../pages/iframe/index.jsx'))
const Goalsetting = lazy(() => import('../pages/goalSetting'));  
const AnnualOverview = lazy(()=>import('../pages/goalSetting/annualOverview/index'))  //历史总览
const GoalAttainment = lazy(() => import('../pages/goalAttainment'));  //目标达成
const CauseAnalysis = lazy(() => import('../pages/causeAnalysis'));  //成因分析
const PortraitAnalysis = lazy(() => import('../pages/customerShipList/portraitAnalysis'));  //画像分析
const MemberProfile = lazy(() => import('../pages/customerShipList/memberProfile'));  //会员概览





const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const info = useAtomValue(loginBasicInfoAtom);
  const auth = !!info.brandIdenty
  useEffect(() => {
    if (!auth) {
      navigate('/login', {
        state: { from: location },
        replace: true,
      });
    }
    
  }, [navigate, location]);

  return auth ? children : null;
};

const routes= [
  {
    path : '/',
    element :<Navigate to="/home" replace />,
    private:true, //是否需要登录后才能访问
  },
  {
    path : '/login',
    element : <Login />,
    private:false
  },
  {
    path : '/home',
    element : <Home />,
    private:true
  },
  {
    path : '/main',
    element : <Main />,
    private:true
  },
  // {
  //   path : '/test',
  //   element : <Test />,
  //   private:false
  // },
  {
    path : '/goalsetting',
    element : <Goalsetting />,
    private:true
  },
  
  {
    path : '/annualOverview',
    element : <AnnualOverview />,
    private:true
  },
  {
    path : '/goalAttainment',
    element : <GoalAttainment />,
    private:true
  },
  {
    path : '/causeAnalysis',
    element : <CauseAnalysis />,
    private:true
  },
  // {
  //   path : '/portraitAnalysis',
  //   element : <PortraitAnalysis />,
  //   private:true
  // },
  // {
  //   path : '/memberProfile',
  //   element : <MemberProfile />,
  //   private:true
  // },
  
  
  
]
  const RouterComponent = function(){
    return (<Routes>
      {routes.map((route,index)=>{
        if(route.private){
          return <Route key={index}  path={route.path} element={<PrivateRoute> {route.element}</PrivateRoute>}></Route>
  
        }else{
          return <Route key={index}  path={route.path} element={route.element}></Route>
    
        }
      })}
     {/* <Route path="/" element={<PrivateRoute> <Home /></PrivateRoute>} />
     <Route path="/login" element={<Login />} />
     <Route path="/home" element={<PrivateRoute> <Home /></PrivateRoute>} /> */}
    </Routes>)
   
  }
  export default RouterComponent