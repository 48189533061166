 let shopMenu = [
  {
    key: "1",
    icon: <img src={require("../assets/shouye.png")} />,
    activeIcon: <img src={require("../assets/shouye_a.png")} />,
    label: "首页",
    url: "/home",
  },
  {
    key: "2",
    icon: <img src={require("../assets/pinxiang.png")} />,
    activeIcon: <img src={require("../assets/pinxiang_a.png")} />,
    label: "品项",
    children: [
      {
        key: "2-1",
        // icon: <PieChartOutlined />,
        label: "品项类别管理",
        url: "/main",
        code: "SUPPLIER_AUTHORITY",
        iframeurl: "/marketing/internal/dishShopType/dishGroup",
      },
      {
        key: "2-2",
        // icon: <PieChartOutlined />,
        label: "品项管理",
        url: "/main",
        iframeurl: "/marketing/internal/dishShop/dishShopMain",
        code: "DISH_AUTHORITY",
      },
      {
        key: "2-3",
        // icon: <PieChartOutlined />,
        label: "开单带入品项",
        url: "/main",
        iframeurl: "/marketing/internal/bandleTableDish/tableDish",
        code: "BANDLE_TABLE_DISH",
      },
      {
        key: "2-4",
        // icon: <PieChartOutlined />,
        label: "次卡管理",
        url: "/main",
        iframeurl: "/marketing/internal/cardTime/cardTimeMain",
        code: "DISH_CARDTIME_AUTHORITY",
      },

      {
        key: "2-5",
        // icon: <PieChartOutlined />,
        label: "储值权益卡管理",
        url: "/main",
        iframeurl: "/marketing/internal/savecCard/saveCardMain",
        code: "DISH_SAVE_CARD_AUTHORITY",
      },
      {
        key: "2-6",
        // icon: <PieChartOutlined />,
        label: "供货来源管理",
        url: "/main",
        code: "SUPPLIER_AUTHORITY",
        code: "SUPPLIER_AUTHORITY",
        iframeurl: "/marketing/internal/supplier/list",
      },

      {
        key: "2-7",
        // icon: <PieChartOutlined />,
        label: "品项入库记录",
        url: "/main",
         code:'DISH_AUTHORITY',
        iframeurl: "/marketing/internal/dishShop/purchase/purchaseList",
      },
      {
        key: "2-8",
        // icon: <PieChartOutlined />,
        label: "库存盘点",
        url: "/main",
        code: "DISH_INVENTORY",
        iframeurl: "/marketing/internal/dishShop/inventory/main",
      },
      {
        key: "2-9",
        // icon: <PieChartOutlined />,
        label: "物料管理",
        url: "/main",
        code: "DISH_RAW_MATERIAL",
        iframeurl: "/marketing/internal/dishRawMaterial/dishList",
      },
      {
        key: "2-10",
        // icon: <PieChartOutlined />,
        label: "物料领取记录",
        url: "/main",
        code: "RECEIVE_DISH",
        iframeurl: "/marketing/internal/receiveDish/receiveHistory",
      },
    ],
  },
  {
    key: "3",
    icon: <img src={require("../assets/huiyuan.png")} />,
    activeIcon: <img src={require("../assets/huiyuan_a.png")} />,
    label: "会员",
    children: [
      // {
      //   key: "3-14",
      //   // icon: <PieChartOutlined />,
      //   label: "会员概览",
      //   // url: "/main",
      //   //  code:'CUSTOMER_SEE',
      //   // iframeurl: "/marketing/customerModule/list",
      //   url:'/memberProfile'
      // },
      // {
      //   key: "3-13",
      //   // icon: <PieChartOutlined />,
      //   label: "画像分析",
      //   // url: "/main",
      //   //  code:'CUSTOMER_SEE',
      //   // iframeurl: "/marketing/customerModule/list",
      //   url:'/portraitAnalysis'
      // },
      {
        key: "3-1",
        // icon: <PieChartOutlined />,
        label: "会员列表",
        url: "/main",
        //  code:'CUSTOMER_SEE',
        iframeurl: "/marketing/customerModule/list",
      },
      {
        key: "3-2",
        // icon: <PieChartOutlined />,
        label: "会员卡耗",
        url: "/main",
        code: "CUSTOMER_ASSET_CONSUMPTION",
        iframeurl: "/marketing/internal/customer/drain/customerConsume",
      },
      {
        key: "3-3",
        // icon: <PieChartOutlined />,
        label: "会员迁移",
        url: "/main",
        //  code:'SUPPLIER_AUTHORITY',
        iframeurl: "/marketing/customerModule/changeCustomerPage",
      },
      {
        key: "3-4",
        // icon: <PieChartOutlined />,
        label: "瘦身分析",
        url: "/main",
         code:'CUSTOMER_ATTRIBUTE',
        iframeurl: "/marketing/internal/customerAttribute/analysisAttribute",
      },

      // {
      //     key: '3-5',
      //     // icon: <PieChartOutlined />,
      //     label: '瘦身排行榜',
      //       url:'/main',
      //      code:'SUPPLIER_AUTHORITY',
      // },
      {
        key: "3-6",
        // icon: <PieChartOutlined />,
        label: "会员标签",
        url: "/main",
        //  code:'SUPPLIER_AUTHORITY',
        iframeurl: "/marketing/internal/customer/lable/list",
      },

      {
        key: "3-7",
        // icon: <PieChartOutlined />,
        label: "会员分析",
        url: "/main",
        code: "ANALYSIS_CUSTOMER_REPORT",
        iframeurl:
          "/marketing/internal/customerAnalysis/customerAnalysisMainPage",
      },
      {
        key: "3-8",
        // icon: <PieChartOutlined />,
        label: "消费周期分析",
        url: "/main",
        code: "ANALYSIS_CUSTOMER_SPEND",
        iframeurl: "/marketing/internal/customerAnalysis/spendFrequency",
      },
      {
        key: "3-9",
        // icon: <PieChartOutlined />,
        label: "会员画像",
        url: "/main",
        //  code:'SUPPLIER_AUTHORITY',
        iframeurl: "/marketing/internal/customer/agePortait",
      },
      {
        key: "3-10",
        // icon: <PieChartOutlined />,
        label: "会员查询规则",
        url: "/main",
        //  code:'SUPPLIER_AUTHORITY',
        iframeurl: "/marketing/internal/customerSetting/searchGroup",
      },
      {
        key: "3-11",
        // icon: <PieChartOutlined />,
        label: "会员客情任务",
        url: "/main",
        //  code:'SUPPLIER_AUTHORITY',
        iframeurl: "/marketing/internal/customerTask/mainPage",
      },
      {
        key: "3-12",
        // icon: <PieChartOutlined />,
        label: "评价反馈",
        url: "/main",
        //  code:'SUPPLIER_AUTHORITY',
        iframeurl: "/marketing/internal/customer/feedback/list",
      },
    ],
  },
  {
    key: "4",
    label: "营销",
    icon: <img src={require("../assets/yingxiao.png")} />,
    activeIcon: <img src={require("../assets/yingxiao_a.png")} />,
    url: "/main",
    //  code:'SUPPLIER_AUTHORITY',
    iframeurl: "/marketing",
  },
  {
    key: "5",
    label: "报表",
    icon: <img src={require("../assets/baobiao.png")} />,
    activeIcon: <img src={require("../assets/baobiao_a.png")} />,
    children: [
      {
        key: "5-1",
        // icon: <PieChartOutlined />,
        label: "经营报表",
        children: [
          {
            key: "5-1-1",
            // icon: <PieChartOutlined />,
            label: "营业看板",
            url: "/main",
            iframeurl: "/marketing/internal/dashboard/page",
             code:'DASHBORAD_REPORT',
            iframeurlparams: {
              searchDay: 1,
              rquestSource: 3,
            },
          },
          {
            key: "5-1-2",
            // icon: <PieChartOutlined />,
            label: "每日对账报表",
            url: "/main",
            iframeurl: "/marketing/internal/sales/saleReport",
            code: "ACCOUNTS_ERPORT",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-1-3",
            // icon: <PieChartOutlined />,
            label: "营业统计",
            url: "/main",
            iframeurl: "/marketing/internal/shopReport/reportSaleMainPage",
            code: "SALE_SHOP_TOTAL",
            iframeurlparams: {
              rquestSource: 1,
              searchDay: 1,
            },
          },
          {
            key: "5-1-4",
            // icon: <PieChartOutlined />,
            label: "会员卡耗",
            url: "/main",
            iframeurl: "/marketing/internal/saveAndCardReport/assetPage",
            code: "CUSTOMER_ASSET_CONSUMPTION",
            iframeurlparams: {
              rquestSource: 1,
              searchDay: 1,
            },
          },

          {
            key: "5-1-5",
            // icon: <PieChartOutlined />,
            label: "品项利润",
            url: "/main",
            iframeurl: "/marketing/internal/dishProfit/list",
            code: "WEB_DISH_PROFIT",
            iframeurlparams: {
              rquestSource: 3,
              searchDay: 1,
            },
          },
          {
            key: "5-1-6",
            // icon: <PieChartOutlined />,
            label: "小程序支付对账",
            url: "/main",
            iframeurl:
              "/marketing/internal/electlectronic/payment/wechat/account",
             code:'REPORT_PAYMENT',
            iframeurlparams: {
              rquestSource: 1,
              searchDay: 1,
            },
          },

          {
            key: "5-1-7",
            // icon: <PieChartOutlined />,
            label: "线下电子支付对账",
            url: "/main",
            iframeurl: "/marketing/internal/electlectronic/payment/account",
             code:'REPORT_PAYMENT',
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-1-8",
            // icon: <PieChartOutlined />,
            label: "支付方式报表",
            url: "/main",
            iframeurl: "/marketing/internal/payment/report",
            code: "REPORT_PAYMENT",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          // {
          //     key: '5-1-9',
          //     // icon: <PieChartOutlined />,
          //     label: '营业报表',
          // },
          {
            key: "5-1-10",
            // icon: <PieChartOutlined />,
            label: "收支报表",
            url: "/main",
            iframeurl:
              "/marketing/internal/expenditureReport/expenditureReport",
            code: "WEB_EXPENDITURE_REPORT",
            iframeurlparams: {
              rquestSource: 1,
              searchDay: 1,
            },
          },
          {
            key: "5-1-11",
            // icon: <PieChartOutlined />,
            label: "挂账报表",
            url: "/main",
            iframeurl: "/marketing/internal/onAccount/report",
            code: "ACCOUNT_AMOUNT_REPORT",
            iframeurlparams: {
              rquestSource: 1,
              searchDay: 1,
            },
          },
          {
            key: "5-1-12",
            // icon: <PieChartOutlined />,
            label: "优惠报表",
            url: "/main",
            iframeurl: "/marketing/internal/privilege/report",
            code: "PRIVILEGE_REPORT",
            iframeurlparams: {
              rquestSource: 1,
              searchDay: 1,
            },
          },
          {
            key: "5-1-13",
            // icon: <PieChartOutlined />,
            label: "寄存报表",
            url: "/main",
            iframeurl: "/marketing/internal/deposit/report",
            code: "DEPOSIT_REP0RT",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-1-14",
            // icon: <PieChartOutlined />,
            label: "跨店消费报表",
            url: "/main",
            iframeurl: "/marketing/internal/crossSC/tradeMsg",
            code: "CROSS_SHOP_SALES_REPORT",
            iframeurlparams: {
              rquestSource: 1,
              searchDay: 1,
            },
          },
        ],
      },
      {
        key: "5-2",
        // icon: <PieChartOutlined />,
        label: "经营分析",
        children: [
          {
            key: "5-2-1",
            // icon: <PieChartOutlined />,
            label: "销售数据分析",
            url: "/main",
            iframeurl: "/marketing/internal/dishAnalysis/dishAnalysisMainPage",
            code: "ANALYSIS_DISH_REPORT",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-2-2",
            // icon: <PieChartOutlined />,
            label: "会员分析",
            url: "/main",
            iframeurl:
              "/marketing/internal/customerAnalysis/customerAnalysisMainPage",
            code: "ANALYSIS_CUSTOMER_REPORT",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-2-3",
            // icon: <PieChartOutlined />,
            label: "会员消费周期分析",
            url: "/main",
            iframeurl: "/marketing/internal/customerAnalysis/spendFrequency",
            code: "ANALYSIS_CUSTOMER_SPEND",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
        ],
      },
      {
        key: "5-3",
        // icon: <PieChartOutlined />,
        label: "销售报表",
        children: [
          {
            key: "5-3-1",
            // icon: <PieChartOutlined />,
            label: "品项销售报表",
            url: "/main",

            iframeurl: "/marketing/internal/dish/dishReport",
            code: "DISH_REP0RT",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-3-2",
            // icon: <PieChartOutlined />,
            label: "品项类别销售报表",
            url: "/main",
            code: "DISH_TYPE_REP0RT",
            iframeurl: "/marketing/internal/dishType/dishTypeReport",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-3-3",
            // icon: <PieChartOutlined />,
            label: "次卡销售报表",
            url: "/main",
            iframeurl: "/marketing/internal/cardTimeReport/cardTime",
            code: "CARD_TIME_REPORT",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-3-4",
            // icon: <PieChartOutlined />,
            label: "次卡核销报表",
            url: "/main",
            iframeurl: "/marketing/internal/useCardTime/useReport",
            code: "WEB_USE_CRADE_TIME_REPORT",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-3-5",
            // icon: <PieChartOutlined />,
            label: "储值权益卡销售报表",
            url: "/main",
            iframeurl: "/marketing/internal/saveCardReport/sale",
             code:'CARD_TIME_REPORT',
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-3-6",
            // icon: <PieChartOutlined />,
            label: "储值权益卡核销报表",
            url: "/main",
            iframeurl: "/marketing/internal/saveCardReport/use",
             code:'WEB_USE_CRADE_TIME_REPORT',
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-3-7",
            // icon: <PieChartOutlined />,
            label: "储值报表",
            url: "/main",
            iframeurl: "/marketing/internal/saveReport/customerSave",
            code: "CUSTOMER_SAVE_REPORT",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-3-8",
            // icon: <PieChartOutlined />,
            label: "工作台报表",
            url: "/main",
            iframeurl: "/marketing/internal/tablesReport/tablesSales",
             code:'TRADE_TABLES_REPORT',
            iframeurlparams: {
              rquestSource: 1,
            },
          },
        ],
      },
      {
        key: "5-4",
        // icon: <PieChartOutlined />,
        label: "会员报表",
        children: [
          {
            key: "5-4-1",
            // icon: <PieChartOutlined />,
            label: "会员到店报表",
            url: "/main",
            code: "CUSTOMER_SHOP_REPORT",
            iframeurl: "/marketing/internal/report/customerShop",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-4-2",
            // icon: <PieChartOutlined />,
            label: "会员报表",
            url: "/main",
            code: "MEMBER_REP0RT",
            iframeurl: "/marketing/internal/report/customerReport",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
        ],
      },

      {
        key: "5-5",
        // icon: <PieChartOutlined />,
        label: "员工报表",
        children: [
          {
            key: "5-5-1",
            // icon: <PieChartOutlined />,
            label: "员工业绩报表",
            url: "/main",
            code: "STAFF_SALARY_REPORT",
            iframeurl: "/marketing/internal/report/userReport",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-5-2",
            // icon: <PieChartOutlined />,
            label: "员工服务报表",
            url: "/main",
            iframeurl: "/marketing/internal/report/staff/staffDish",
             code:'WEB_USER_DC_REPORT',
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-5-3",
            // icon: <PieChartOutlined />,
            label: "上钟时长报表",
            url: "/main",
            code: "STAFF_WORK_TIME_REP0RT",
            iframeurl: "/marketing/internal/staffWorkTime/timeReport",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
        ],
      },
      {
        key: "5-6",
        // icon: <PieChartOutlined />,
        label: "库存管理报表",
        children: [
          {
            key: "5-6-1",
            // icon: <PieChartOutlined />,
            label: "进销存报表",
            url: "/main",
            code: "DISH_KC_REPORT",
            iframeurl: "/marketing/internal/purchaseSale/report",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-6-2",
            // icon: <PieChartOutlined />,
            label: "品项入库报表",
            url: "/main",
            code: "DISH_RK_ERPORT",
            iframeurl: "/marketing/internal/purchaseSale/purchase",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-6-3",
            // icon: <PieChartOutlined />,
            label: "剩余库存资产",
            url: "/main",
            code: "DISH_ASSET_ERPORT",
            iframeurl: "/marketing/internal/purchaseSale/dishAssetReport",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
        ],
      },
      {
        key: "5-7",
        // icon: <PieChartOutlined />,
        label: "营销报表",
        children: [
          {
            key: "5-7-1",
            // icon: <PieChartOutlined />,
            label: "推广提成报表",
            url: "/main",
            code: "WEB_EXPADEND_REPORT",
            iframeurl: "/marketing/internal/expanded/report",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-7-2",
            // icon: <PieChartOutlined />,
            label: "场景营销报表",
            url: "/main",
            code: "MARKETING_REP0RT",
            iframeurl: "/marketing/internal/marketingReport/marketing",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-7-3",
            // icon: <PieChartOutlined />,
            label: "特价活动报表",
            url: "/main",
            code: "ACTIVITY_SALES_REPORT",
            iframeurl: "/marketing/internal/activitySalesReport/activitySales",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-7-4",
            // icon: <PieChartOutlined />,
            label: "优惠券报表",
            url: "/main",
            code: "COUPON_REP0RT",
            iframeurl: "/marketing/internal/marketingReport/coupon",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-7-5",
            // icon: <PieChartOutlined />,
            label: "优惠券使用报表",
            url: "/main",
            code: "COUPON_USE_REP0RT",
            iframeurl: "/marketing/internal/marketingReport/coupnUseReport",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-7-6",
            // icon: <PieChartOutlined />,
            label: "积分统计报表",
            url: "/main",
            code: "CUSTOMER_INTEGRAL_REP0RT",
            iframeurl: "/marketing/internal/integralReport/integralList",
            iframeurlparams: {
              rquestSource: 1,
            },
          },
        ],
      },
      {
        key: "5-8",
        // icon: <PieChartOutlined />,
        label: "预约报表",
        children: [
          {
            key: "5-8-1",
            // icon: <PieChartOutlined />,
            label: "预约报表",
            url: "/main",
            iframeurl: "/marketing/internal/bookingReport/booking",
             code:'BOOKING_REP0RT',
            iframeurlparams: {
              rquestSource: 1,
            },
          },
          {
            key: "5-8-2",
            // icon: <PieChartOutlined />,
            label: "员工预约报表",
            url: "/main",
            iframeurl: "/marketing/internal/bookingReport/staffBooking",
             code:'BOOKING_REP0RT',
            iframeurlparams: {
              rquestSource: 1,
            },
          },
        ],
      },
    ],
  },
  {
    key: "6",
    label: "目标",
    icon: <img src={require("../assets/mubiao.png")} />,
    activeIcon: <img src={require("../assets/mubiao_a.png")} />,

    children: [
      {
        key: "6-1",
        // icon: <PieChartOutlined />,
        label: "目标达成",
        url:'/goalAttainment'
      },
      {
        key: "6-2",
        // icon: <PieChartOutlined />,
        label: "成因分析",
        url:'/causeAnalysis'
      },
      {
        key: "6-3",
        // icon: <PieChartOutlined />,
        label: "目标设定",
        url: "/goalsetting",
      },
    ],
  },
  {
    key: "7",
    label: "员工",
    icon: <img src={require("../assets/yuangong.png")} />,
    activeIcon: <img src={require("../assets/yuangong_a.png")} />,
    url: "/main",
    //    code:'SUPPLIER_AUTHORITY',
    iframeurl: "/marketing/internal/staff/managerPage",
  },
  {
    key: "8",
    label: "订单",
    icon: <img src={require("../assets/dingdan.png")} />,
    activeIcon: <img src={require("../assets/dingdan_a.png")} />,

    children: [
      {
        key: "8-1",
        // icon: <PieChartOutlined />,
        label: "订单列表",
        url: "/main",
        //  code:'SUPPLIER_AUTHORITY',
        iframeurl: "/marketing/internal/trade/tradeList",
      },
      {
        key: "8-2",
        // icon: <PieChartOutlined />,
        label: "配送列表",
        url: "/main",
        //  code:'SUPPLIER_AUTHORITY',
        iframeurl: "/marketing/internal/deliver/list",
      },
      {
        key: "8-3",
        // icon: <PieChartOutlined />,
        label: "预约订单",
        url: "/main",
        code: "WEB_BOOKING",
        iframeurl: "/marketing/internal/bookingTrade/bookingList",
      },
      {
        key: "8-4",
        // icon: <PieChartOutlined />,
        label: "小程序购买核销",
        url: "/main",
        //  code:'SUPPLIER_AUTHORITY',
        iframeurl: "/marketing/internal/writeOff",
      },
      {
        key: "8-5",
        // icon: <PieChartOutlined />,
        label: "小程序订单核销",
        url: "/main",
        //  code:'SUPPLIER_AUTHORITY',
        iframeurl: "/marketing/internal/wxTradeCustomer/listData",
      },
    ],
  },
  {
    key: "9",
    label: "人效",
    icon: <img src={require("../assets/renxiao.png")} />,
    activeIcon: <img src={require("../assets/renxiao_a.png")} />,
    children: [
      {
        key: "9-1",
        // icon: <PieChartOutlined />,
        label: "人效方案配置",
        version‌:'v3',
        children: [
          {
            key: "9-1-1",
            // icon: <PieChartOutlined />,
            label: "人效方案管理",
            url: "/main",
            //  code:'SUPPLIER_AUTHORITY',
            iframeurl: "/marketing/commissionPlanCfg/mainPage",
          },
          {
            key: "9-1-2",
            // icon: <PieChartOutlined />,
            label: "服务类型配置",
            url: "/main",
            code: "WEB_SALARY_JOIN_MODLE",
            iframeurl: "/marketing/salaryStafffModel/settingJoinModel",
          },
          {
            key: "9-1-3",
            // icon: <PieChartOutlined />,
            label: "员工选择配置",
            url: "/main",
            code: "WEB_SALARY_SELECT_MODLE",
            iframeurl:
              "/marketing/salaryStafffModel/settingSelectModel",
          },
        ],
      },
      {
        key: "9-2",
        // icon: <PieChartOutlined />,
        label: "人效核算",
        version‌:'v3',
        children: [
          {
            key: "9-2-1",
            // icon: <PieChartOutlined />,
            label: "人效数据核算V3",
            url: "/main",
            code:'TELENT_SEE',
            iframeurl: "/marketing/internal/salaryV3/v3StaffSalaryData",
            iframeurlparams: {
              sourceType: 2,
            },
          },
          {
            key: "9-2-2",
            // icon: <PieChartOutlined />,
            label: "整单品项提成",
            url: "/main",
            code: "TRADE_ITEM_SALARY",
            iframeurl: "/marketing/internal/tradeSalary/listItemSalary",
          },
          {
            key: "9-2-3",
            // icon: <PieChartOutlined />,
            label: "默认角色提成",
            url: "/main",
            code: "DEFAULT_ROLE_SALARY",
            iframeurl: "/marketing/internal/salary/defaultRole",
          },
        ],
      },
      {
        key: "9-3",
        // icon: <PieChartOutlined />,
        label: "业绩统计",
        version‌:'v3',
        children: [
          {
            key: "9-3-1",
            // icon: <PieChartOutlined />,
            label: "员工售卡业绩",
            url: "/main",
            code: "WEB_CARD_SALES_REPORT",
            iframeurl: "/marketing/internal/salaryCard/listUserSalary",
          },
          {
            key: "9-3-2",
            // icon: <PieChartOutlined />,
            label: "员工消卡业绩",
            url: "/main",
            code: "WEB_CARD_USE_REPORT",
            iframeurl: "/marketing/internal/useCard/listUserSalary",
          },
          {
            key: "9-3-3",
            // icon: <PieChartOutlined />,
            label: "员工储值业绩",
            url: "/main",
            code: "CUSTOMER_SAVE_SALARY",
            iframeurl: "/marketing/internal/useSaveSalary/listUserSalary",
          },
          {
            key: "9-3-4",
            // icon: <PieChartOutlined />,
            label: "股东分红",
            url: "/main",
            code: "SHARE_BONUS",
            iframeurl: "/marketing/internal/shareBonus/mainPage",
          },
        ],
      },
      {
        key: "9-4",
        // icon: <PieChartOutlined />,
        label: "项目提成配置",
        version‌:'v4',
        url:'/projectCommission'
      },
      {
        key: "9-5",
        // icon: <PieChartOutlined />,
        label: "次卡开卡提成",
        version‌:'v4',
        url:'/cardCommission'
      },
      {
        key: "9-6",
        // icon: <PieChartOutlined />,
        label: "储值权益卡开卡提成",
        version‌:'v4',
        url:'/storeCardCommission'
      },
      {
        key: "9-7",
        // icon: <PieChartOutlined />,
        label: "储值提成",
        version‌:'v4',
        url:'/storeAmountCommission'
      },
      {
        key: "9-8",
        // icon: <PieChartOutlined />,
        label: "总业绩提成配置",
        version‌:'v4',
        url:'/totalPerformance'
      },
    ],
  },
  {
    key: "10",
    label: "设置",
    icon: <img src={require("../assets/shezhi.png")} />,
    activeIcon: <img src={require("../assets/shezhi_a.png")} />,
    children: [
      {
        key: "10-1",
        // icon: <PieChartOutlined />,
        label: "门店设置",
        children: [
          {
            key: "10-1-1",
            // icon: <PieChartOutlined />,
            label: "门店信息设置",
            url: "/main",
            code: "WEB_SHOP_NOTICE",
            iframeurl: "/marketing/commercial/baseSetting",
          },
          // {
          //     key: '10-1-2',
          //     // icon: <PieChartOutlined />,
          //     label: '小程序账号配置',
          // },
          // {
          //     key: '10-1-3',
          //     // icon: <PieChartOutlined />,
          //     label: '小程序信息设置',
          // },
          {
            key: "10-1-4",
            // icon: <PieChartOutlined />,
            label: "信息推送设置",
            url: "/main",
             code:'WEB_SHOP_BASEMESSAGE',
            iframeurl: "/marketing/wxPushMsgSetting/settingPage",
          },
          {
            key: "10-1-5",
            // icon: <PieChartOutlined />,
            label: "设备管理",
            url: "/main",
             code:'WEB_SHOP_BASEMESSAGE',
            iframeurl: "/marketing/deviceManaer/settingPage",
          },
          {
            key: "10-1-6",
            // icon: <PieChartOutlined />,
            label: "堂口商品配置",
            url: "/main",
             code:'WEB_SHOP_BASEMESSAGE',
            iframeurl: "/marketing/printerDishSetting/printerDevice",
          },
          {
            key: "10-1-7",
            // icon: <PieChartOutlined />,
            label: "智能开关管理",
            url: "/main",
             code:'WEB_SHOP_BASEMESSAGE',
            iframeurl: "/marketing/internal/powerSwitch/list",
          },
          {
            key: "10-1-8",
            // icon: <PieChartOutlined />,
            label: "权限授权管理",
            url: "/main",
            code: "WEB_AUTHORIZED_AUDIT",
            iframeurl: "/marketing/authorizedAudit/setting",
          },
          {
            key: "10-1-9",
            // icon: <PieChartOutlined />,
            label: "报表设置",
            url: "/main",
             code:'WEB_SHOP_BASEMESSAGE',
            iframeurl: "/marketing/internal/reportSetting/settingShowMsg",
          },
          {
            key: "10-1-10",
            // icon: <PieChartOutlined />,
            label: "清台设置",
            url: "/main",
             code:'WEB_SHOP_BASEMESSAGE',
            iframeurl:
              "/marketing/internal/commercailSetting/cleanTableSetting",
          },
          {
            key: "10-1-11",
            // icon: <PieChartOutlined />,
            label: "门店基础设置",
            url: "/main",
             code:'WEB_SHOP_BASEMESSAGE',
            iframeurl: "/marketing/commercialSettig/setting",
          },
        ],
      },
      {
        key: "10-2",
        // icon: <PieChartOutlined />,
        label: "工作台设置",
        children: [
          {
            key: "10-2-1",
            // icon: <PieChartOutlined />,
            label: "工作台管理",
            url: "/main",
            code: "DISK_MODIFY",
            iframeurl: "/marketing/internal/tables/managerTablePage",
          },
          {
            key: "10-2-2",
            // icon: <PieChartOutlined />,
            label: "开台带入项目设置",
            url: "/main",
             code:'BANDLE_TABLE_DISH',
            iframeurl: "/marketing/internal/bandleTableDish/tableDish",
          },
        ],
      },
      {
        key: "10-3",
        // icon: <PieChartOutlined />,
        label: "会员设置",
        children: [
          {
            key: "10-3-1",
            // icon: <PieChartOutlined />,
            label: "会员等级设置",
            url: "/main",
             code:'CUSTOMER_SETTING',
            iframeurl:
              "/marketing/internal/customerSetting/customerLevelSetting",
          },
          {
            key: "10-3-2",
            // icon: <PieChartOutlined />,
            label: "储值金额设置",
            url: "/main",
             code:'CUSTOMER_SETTING',
            iframeurl: "/marketing/internal/customerStored/storedSettingPage",
          },
          {
            key: "10-3-3",
            // icon: <PieChartOutlined />,
            label: "储值支付设置",
            url: "/main",
             code:'CUSTOMER_SETTING',
            iframeurl: "/marketing/internal/customerSetting/customerPaySetting",
          },
          {
            key: "10-3-4",
            // icon: <PieChartOutlined />,
            label: "会员查询规则设置",
            url: "/main",
             code:'CUSTOMER_SETTING',
            iframeurl: "/marketing/internal/customerSetting/searchGroup",
          },
          {
            key: "10-3-5",
            // icon: <PieChartOutlined />,
            label: "员工端会员设置",
            url: "/main",
             code:'CUSTOMER_SETTING',
            iframeurl:
              "/marketing/internal/customerSetting/staffManagerSetting",
          },
        ],
      },
    ],
  },
];
 const brandMenu = [
  {
    key: "1",
    icon: <img src={require("../assets/shouye.png")} />,
    activeIcon: <img src={require("../assets/shouye_a.png")} />,
    label: "首页",
    url: "/main",
    iframeurl: "/marketing/brand/index",
  },
  {
    key: "2",
    icon: <img src={require("../assets/shouye.png")} />,
    activeIcon: <img src={require("../assets/shouye_a.png")} />,
    label: "门店",
    url: "/main",
    iframeurl: "/marketing/internal/brand/shopList",
  },
   {
    key: "9",
    label: "目标",
    icon: <img src={require("../assets/mubiao.png")} />,
    activeIcon: <img src={require("../assets/mubiao_a.png")} />,

    children: [
      {
        key: "9-1",
        // icon: <PieChartOutlined />,
        label: "目标达成",
        url: "/goalAttainment",
      },
      {
        key: "9-2",
        // icon: <PieChartOutlined />,
        label: "成因分析",
        url:'/causeAnalysis'
      },
      {
        key: "9-3",
        // icon: <PieChartOutlined />,
        label: "目标设定",
        url: "/goalsetting",
      },
    ],
  },
  {
    key: "3",
    icon: <img src={require("../assets/yuangong.png")} />,
    activeIcon: <img src={require("../assets/yuangong_a.png")} />,

    label: "员工",
    children: [
      {
        key: "3-1",
        label: "品牌员工角色",
        url: "/main",
        iframeurl: "/marketing/internal/brand/staff/staffRole",
        code:'WEB_BRAND_STAFF_ROLE'
      },
      {
        key: "3-2",
        label: "品牌员工管理",
        url: "/main",
        iframeurl: "/marketing/internal/brand/staff/staffList",
        code:'WEB_BRAND_STAFF'
      },
      {
        key: "3-3",
        label: "员工调配",
        url: "/main",
        iframeurl: "/marketing/internal/brand/shopLis",
        code:'WBE_CHANGE_STAFF_SHOP'
      },
    ],
  },
  {
    key: "4",
    icon: <img src={require("../assets/pinxiang.png")} />,
    activeIcon: <img src={require("../assets/pinxiang_a.png")} />,

    label: "品项",
    children: [
      {
        key: "3-1",
        label: "品项类别管理",
        url: "/main",
        iframeurl: "/marketing/brand/dishShopType/dishGroup",
        code:'WEB_BRAND_DISH_TYPE'
      },
      {
        key: "3-2",
        label: "品项管理",
        url: "/main",
        iframeurl: "/marketing/brand/dishShop/dishShopMain",
        code:'WEB_BRAND_DISH_SHOP'
      },
      {
        key: "3-3",
        label: "次卡管理",
        url: "/main",
        iframeurl: "/marketing/brand/cardTime/cardTimeMainPage",
        code:'WEB_BRAND_CARD_TIME'
      },
      {
        key: "3-4",
        label: "供货来源",
        url: "/main",
        iframeurl: "/marketing/brand/supplier/list",
        code:'WEB_BRAND_SUPPLIER'
      },
      {
        key: "3-5",
        label: "门店库存",
        url: "/main",
        iframeurl: "/marketing/brand/stock/shopList",
        code:'WEB_BRAND_STOCK'
      },
      {
        key: "3-6",
        label: "调拨单管理",
        url: "/main",
        iframeurl: "/marketing/brand/allocation/list",
        code:'WEB_BRAND_ALLOCATION_ORDER'
      },
    ],
  },
  {
    key: "5",
    icon: <img src={require("../assets/huiyuan.png")} />,
activeIcon: <img src={require("../assets/huiyuan_a.png")} />,
label: "会员",
    children: [
      {
        key: "5-1",
        label: "会员列表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/customer/shopList",
        code:'WEN_BRAND_CUSTOMER_LIST'
      },
      {
        key: "5-2",
        label: "会员画像",
        url: "/main",
        iframeurl: "/marketing/brand/customer/agePortait",
        code:'WEB_BRAND_CUSTOMER_MODLE'
      },
    ],
  },
  {
    key: "6",
    icon: <img src={require("../assets/yingxiao.png")} />,
    activeIcon: <img src={require("../assets/yingxiao_a.png")} />,
label: "营销",
url: "/main",
iframeurl: "/marketing/internal/brand/marketing/main",
    
  },
  {
    key: "7",
    icon: <img src={require("../assets/baobiao.png")} />,
    activeIcon: <img src={require("../assets/baobiao_a.png")} />,
label: "报表",
    children: [
      {
        key: "7-1",
        label: "营业报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/dashboard/page",
        code:'WEB_BRAND_SALES_REPORT',
      },
      {
        key: "7-2",
        label: "对账报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/report/reconciliation",
        code:'WEB_BRAND_DZ_REPORT',
      },
      {
        key: "7-3",
        label: "支付方式报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/payment/report",
        // code:'WEB_BRAND_DZ_REPORT',

      },
      {
        key: "7-4",
        label: "门店业绩排行",
        url: "/main",
        iframeurl: "/marketing/internal/brand/report/shopOrder",
        code:'WEB_BRAND_YJ_REPORT'
      },
      {
        key: "7-5",
        label: "挂账报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/shopLis",
        code:'WEB_BRAND_GZ_REPORT'
      },
      {
        key: "7-6",
        label: "优惠报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/shopLis",
        code:'WEB_BRAND_YH_REPORT'
      },
      {
        key: "7-7",
        label: "品项销售报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/report/dishReport",
        code:'WEB_BRAND_PX_REPORT'
      },
      {
        key: "7-8",
        label: "品项类别销售报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/typeReport/dishTypeReport",
        code:'WEB_BRAND_PXT_REPORT'
      },
      {
        key: "7-9",
        label: "储值报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/report/customerSave",
        code:'WEB_BRAND_CZ_REPORT'
      },
      {
        key: "7-10",
        label: "次卡销售排行榜",
        url: "/main",
        iframeurl: "/marketing/internal/brand/cardTimeReport/orderBrand",
        code:'WEB_BRAND_CK_REPORT'

      },
      {
        key: "7-11",
        label: "次卡销售报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/cardTimeReport/cardTime",
        code:'WEB_BRAND_CK_REPORT'

      },
      {
        key: "7-12",
        label: "工作台报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/tablesReport/tablesSales",

      },
      {
        key: "7-13",
        label: "会员到店报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/customer/customerShop",
      },
      {
        key: "7-14",
        label: "会员报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/customer/customerReport",
        code:'WEB_BRAND_HY_REPORT'
      },
      {
        key: "7-15",
        label: "进销存报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/purchaseSale/shopList",
        code:'WEB_BRAND_PX_REPORT'
      },
      {
        key: "7-16",
        label: "品项入库报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/purchaseSale/shopList",
        code:'WEB_BRAND_PX_REPORT'
      },
      {
        key: "7-17",
        label: "场景营销报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/marketingReport/marketing",
        code:'WEB_BRAND_CJ_REPORT'
      },
      {
        key: "7-18",
        label: "特价活动报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/activitySalesReport/activitySales",
        code:'WEB_BRAND_TJ_REPORT'
      },
      {
        key: "7-19",
        label: "优惠券报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/couponReport/coupon",
        code:'WEB_BRAND_YHQ_REPORT'
      },
      {
        key: "7-20",
        label: "预约报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/bookingReport/booking",
        code:'WEB_BRAND_YY_REPORT'
      },
      {
        key: "7-21",
        label: "员工预约报表",
        url: "/main",
        iframeurl: "/marketing/internal/brand/bookingReport/staffBooking",
        code:'WEB_BRAND_YY_REPORT'
      },
    ],
  },
  {
    key: "8",
    icon: <img src={require("../assets/huiyuan.png")} />,
activeIcon: <img src={require("../assets/huiyuan_a.png")} />,
label: "品牌设置",
    children: [
      {
        key: "8-1",
        label: "品牌基本信息设置",
        url: "/main",
        iframeurl: "/marketing/internal/brand/setting/brandBasic",
      },
      {
        key: "8-2",
        label: "权限授权管理",
        url: "/main",
        iframeurl: "/marketing/brand/authorized/setting",
      },
    ],
  },
];
let version = 'v3';
shopMenu = shopMenu.map(menu=>{
  if(menu.label === '人效'){
    menu.children =  menu.children.filter(i=>i.version‌ === version)
  }
  return menu
})
export {shopMenu,brandMenu}

